import React from 'react';
import ButtonLink from '../components/ButtonLink';
import Project from '../components/Project';

const About = props => {

    const projects = [
        {
            title: 'cloudfront invalidator for codepipeline',
            description: 'This website is continuously deployed from AWS CodePipeline after each commit and subsequent build/test. Since this website is fetched via CloudFront CDN, the cache needs to be invalidated when new assets are deployed. This code is executed in AWS Lambda, which handles submitting a CloudFront invalidation and CodePipeline report.',
            links: <><ButtonLink target="blank" href="https://github.com/cjo2/cloudfront-invalidator-for-codepipeline">github repo</ButtonLink></>
        }
    ]

    return (
        <>
            <h2>Christopher J. Docuyanan</h2>
            <h3>About me</h3>
            <p>I am a software engineer working across the full-stack, but I am primarily focused on building backend services. I've supported both customer-facing products and building platforms for other engineers. I'm currently at <ButtonLink target="blank" href="https://twilio.com/">Twilio</ButtonLink> working on <ButtonLink target="blank" href="https://segment.com/product/personas/">Twilio Engage</ButtonLink>. 
            Because of the nature of the product at our scale, I work on a distributed architecture building both platform and product using Go, TypeScript, React, AWS, and ClickHouse.</p>
            <p>As a prior digital business consultant at <ButtonLink target="blank" href="https://www.accenture.com/us-en/about/accenture-song-index">Accenture Song</ButtonLink>, I was focused on building modern web applications at non-tech Fortune 500 companies as a front-end software engineer and technical lead.</p>
            <p>If you have a role or just want to chat, feel free to contact me on <ButtonLink target="blank" href="https://www.linkedin.com/in/cjdocuyanan/">LinkedIn</ButtonLink>.</p>
            <hr />
            <h3>Open Source</h3>
            {
                projects.map((project, index) => <Project key={index} {...project} />)
            }
            <hr />
            <h3>Certifications</h3>
            <ul>
                <li>AWS Certified SysOps Administrator - Associate</li>
                <li>AWS Certified Developer - Associate</li>
                <li>AWS Certified Solutions Architect - Associate</li>
                <li>AWS Certified Cloud Practitioner</li>
                <li>Salesforce Certified Administrator</li>
            </ul>

        </>
    );
}

export default About;