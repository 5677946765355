import React from 'react';

const Project = props => {
    
    return (
        <div class="project">
            <div class="project-header">
                <span class="project-title">{props.title}</span>
                <span> | </span>
                <span>{props.links}</span>
            </div>
            <div class="project-description">
                <span>{props.description}</span>
            </div>
        </div>
    )
}

export default Project;