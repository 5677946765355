import './App.css';
import { Switch, Route, Link } from 'react-router-dom';
import About from './pages/About';

function App() {
  return (
    <div className="site-container">
      <div className="site-content">
        <div className="navigation">
          <a href="https://www.linkedin.com/in/cjdocuyanan/" target="blank">LinkedIn</a> | <a href="https://github.com/cjo2" target="blank">GitHub</a>

        </div>
        <div className="main-content">
          <Switch>
            <Route exact path="/">
              <About />
            </Route>
            <Route exact path="/">

            </Route>
          </Switch>
        </div>
        <div className="footer">

        </div>
      </div>
    </div>
  );
}

export default App;
